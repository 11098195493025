@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700;800&display=swap');

/* banner css */
.london_banner {
    background: url('../images/home/banner-3.jpg');
    height: 750px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

.london_banner_text h1 {
    text-transform: capitalize;
    margin: 0;
    letter-spacing: 3px;
    color: #2c2c2c;
    font-size: 70px;
    line-height: 70px;
    font-weight: 400;
}
.Lt_video_banner_content a.london_banner_btn_outline.btn.btn-outline-dark {
    border-color: white;
    color: white;
}
.Lt_video_banner_content a.london_banner_btn_outline.btn.btn-outline-dark:hover {
    color: rgb(0, 0, 0);
}
.london_banner_btn_main button svg {
    font-size: 24px;
}

.london_banner_text p a {
    color: inherit;
    font-weight: 600;
}

.london_banner_text p {
    margin-left: auto;
    color: #000;
    font-size: 16px;
    width: 60%;
    margin-top: 15px;
}

.london_banner_text {
    text-align: right;
}

.london_banner_btn_fill.btn.btn-primary {
    background: #a99575;
    border-color: #a99575;
    border-radius: 0;
    margin-right: 20px;
    padding: 14px 40px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: none;
    outline: none;
}

.london_banner_btn_outline.btn.btn-outline-dark {
    border-color: #000000;
    border-radius: 0;
    padding: 14px 40px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: none;
    outline: none;
}

.london_banner_btn_outline.btn.btn-outline-dark:hover {
    background: white;
    color: #a99575;
    border-color: white;
    transition: all 0.5s;
}

button.london_banner_btn_fill.btn.btn-primary:hover {
    background: #93846a;
    transition: all 0.5s;
}

.london_banner_btn_main {
    margin-top: 45px;
}

.london_banner_text h2 {
    display: block;
    font-family: sans-serif !important;
    font-size: 30px !important;
    text-transform: uppercase !important;
}

@media (min-width:1200px) and (max-width: 1440px) {
    .london_banner_text p {
        width: 80%;
    }

    .london_banner {
        height: 600px;
    }

    .london_banner_text h1 {
        font-size: 60px;
        line-height: 55px;
    }

    .london_banner_btn_main {
        display: flex;
        justify-content: flex-end;
    }

    .london_banner_btn_fill.btn.btn-primary {
        padding: 14px 20px;
        font-weight: 600;
        font-size: 12px;
    }

    .london_banner_btn_outline.btn.btn-outline-dark {
        padding: 9px 20px;
        font-weight: 600;
        font-size: 12px;
    }


}

@media (min-width:992px) and (max-width: 1024px) {
    .london_banner {
        height: 450px;
    }

    .london_banner_btn_fill.btn.btn-primary {
        margin-right: 10px;
        padding: 14px 25px;
        font-size: 13px;
    }

    .london_banner_btn_outline.btn.btn-outline-dark {
        padding: 14px 25px;
        font-size: 13px;
    }

    .london_banner_text h1 {
        font-size: 58px;
        line-height: 60px;
    }

}

@media (min-width:768px) and (max-width: 991px) {
    .london_banner_text p {
        width: 80%;
    }

    .london_banner_text h2 {
        font-size: 24px !important;
    }

    .london_banner {
        height: 500px;
    }

    .london_banner_text h1 {
        font-size: 52px;
        line-height: 52px;
    }

    .london_banner_btn_main {
        margin-top: 25px;
    }

    .london_banner_btn_main {
        display: flex;
        justify-content: flex-end;
    }

    .london_banner_btn_fill.btn.btn-primary {
        padding: 9px 20px;
        font-weight: 600;
        font-size: 12px;
    }

    .london_banner_btn_outline.btn.btn-outline-dark {
        padding: 9px 20px;
        font-weight: 600;
        font-size: 12px;
    }

}

@media (min-width:380px) and (max-width: 767px) {
    .london_banner::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(255 255 255 / 57%) !important;
        height: 100%;
    }
    .london_banner_text h1 {
        font-size: 45px;
        line-height: 40px;
        font-weight: 400;
    }

    .london_banner_text h2 {
        font-size: 24px !important;
    }

    .london_banner_text h1 {
        font-size: 48px;
        line-height: 50px;
        font-weight: 400;
    }

    .london_banner {
        height: 430px;
        background: url('../images/home/banner_mobile_banner.jpg');
        background-position: center;
    }

    .london_banner_text p {
        width: 100%;
        margin-bottom: 15px !important;
    }

    .london_banner_text {
        text-align: center;
    }

    .london_banner_btn_main {
        margin-top: 15px;
    }

    .london_banner::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(255 255 255 / 57%) !important;
        height: 100%;
    }

    .london_banner_btn_fill.btn.btn-primary {
        margin-right: 0 !important;
        padding: 8px 25px;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 400;
    }
}


@media (min-width:320px) and (max-width: 380px) {

    .london_banner_text h2 {
        font-size: 18px !important;
    }

    .london_banner_text h1 {
        font-size: 34px;
        line-height: 30px;
        font-weight: 400;
    }

    .london_banner {
        height: 360px;
        background: url('../images/home/banner_mobile_banner.jpg');
        background-position: center;
    }

    .london_banner_text p {
        font-size: 12px;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px !important;
    }

    .london_banner_text {
        text-align: center;
    }

    .london_banner_btn_main {
        margin-top: 15px;
    }

    .london_banner::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(255 255 255 / 57%);
        height: 100%;
    }

    .london_banner_btn_fill.btn.btn-primary {
        margin-right: 0 !important;
        padding: 8px 25px;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 500;
    }

    button.london_banner_btn_outline.btn.btn-outline-dark {
        padding: 8px 25px;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0;
    }

}

/********************************end banner css **************************/


/* insta css **/
.Lt_insta_main {
    margin-top: 40px;
}
.Lt_insta_section {
    padding: 70px 0px 70px 0px;
}

@media(min-width: 1901px) {
    .insta-iframe {
        height: 410px;
    }
}

@media (min-width:1440px) and (max-width:1900px) {
    .insta-iframe {
        height: 313px;
    }
}

@media(min-width: 1280px) and (max-width: 1439px) {
    .insta-iframe {
        height: 285px;
    }
    .Lt_insta_section {
    padding: 40px 0 20px 0;
}
}

@media(min-width: 992px) and (max-width:1279px) {
    .insta-iframe {
        height: 210px;
    }
}

@media(min-width: 651px) and (max-width:991px) {
    .insta-iframe {
        height: 180px;
    }
}

@media(max-width: 650px) {
    .insta-iframe {
        height: 210px;
    }
    .Lt_insta_section {
        padding: 40px 0px 0px 0px;
    }
}
  
  /**********************************************************/

/* Eng and wedding section css */
.Lt_EngWed_section {
    padding: 100px 0px;
}

.Lt_Eng_img img {
    max-width: 100%;
}

.Lt_Eng_text h3 {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .10em;
    color: #a99575;
    margin-bottom: 13px;
    font-weight: 700;
    font-family: sans-serif !important;
}

.Lt_Eng_text h2 {
    font-size: 38px;
    font-weight: 400;
    line-height: 55px;
    padding: 0 0 25px 0;
    letter-spacing: 0;
}

.Lt_Eng_text {
    padding: 0px 0px 0px 50px
}

.Lt_Eng_text p {
    font-size: 18px;
    font-weight: 400;
    color: #7b7b7b;
    line-height: 32px;
    margin-bottom: 40px;
}

.Lt_fill_btn.btn.btn-primary {
    background: #2c2c2c;
    text-transform: uppercase;
    font-weight: 500;
    height: 55px;
    letter-spacing: .12em;
    padding: 0px 40px;
    border-radius: 0;
    line-height: 55px;
    color: #fff;
    font-weight: 800;
    border-color: #2c2c2c;
    outline: none;
    box-shadow: none;
}

.Lt_fill_btn.btn.btn-primary:hover {
    background: #a99575;
    color: #fff;
    border-color: #a99575;
}


@media (min-width:1200px) and (max-width:1440px) {
    .Lt_Eng_text h2 {
        font-size: 26px;
        font-weight: 400;
        line-height: 34px;
        padding: 0 0 5px 0;
        letter-spacing: 0;
    }

    .Lt_Eng_text p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;
    }

    .Lt_Eng_text h3 {
        font-size: 14px;
        font-weight: 500;
    }

    .Lt_fill_btn.btn.btn-primary {
        font-weight: 500;
        height: 45px;
        line-height: 45px;
        font-weight: 500;
        font-size: 14px;
    }


}

@media (min-width:768px) and (max-width:992px) {
    .Lt_order_2 {
        order: 2;
    }

    .Lt_Eng_img {
        text-align: center;
    }

    .Lt_Eng_text {
        padding: 20px 0px 40px 0px;
        text-align: center;
    }

    .Lt_EngWed_section {
        padding: 60px 0px 30px 0px;
    }

    .Lt_Eng_text h2 {
        font-size: 30px;
        line-height: 35px;
        padding: 0 0 0px 0;
    }

    .Lt_Eng_text h3 {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .Lt_Eng_text p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 15px;
    }

    .Lt_fill_btn.btn.btn-primary {
        height: 45px;
        line-height: 45px;
        font-weight: 500;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .Lt_order_2 {
        order: 2;
    }

    .Lt_Eng_text {
        padding: 20px 0px 40px 0px;
        text-align: center;
    }

    .Lt_EngWed_section {
        padding: 60px 0px 30px 0px;
    }

    .Lt_Eng_text h2 {
        font-size: 28px;
        line-height: 35px;
        padding: 0 0 0px 0;
    }

    .Lt_Eng_text h3 {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .Lt_Eng_text p {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .Lt_fill_btn.btn.btn-primary {
        height: 45px;
        line-height: 45px;
        font-weight: 500;
    }
}

/******************************** End Eng and wedding css **************************/

/* 35 years section css */
.Lt_35years_main {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Lt_35years_section {
    background: #7F6750;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0px;
    position: relative;
}
.Lt_35years_text h2 {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 50px;
    font-family: sans-serif !important;
}

.Lt_35years_text {
    width: 45%;
    text-align: left;
    margin-left: 30px;
}

.Lt_35years_img img {
    max-width: 100%;
}

.bg_left {
    position: absolute;
    left: 0;
    top: 40px;
    opacity: 0.1;
}

.bg_right {
    position: absolute;
    right: 0;
    top: 40px;
    opacity: 0.1;
}

@media (min-width:1200px) and (max-width:1440px) {
.Lt_35years_text {
    width: 35%;
    text-align: left;
    margin-left: 5px;
}
.Lt_35years_text h2 {
    font-size: 36px;
    line-height: normal;
}
.bg_left{
    top: 60px;
    opacity: 0.1;
    width: 12%;
}
.bg_right {
    top: 60px;
    opacity: 0.1;
    width: 12%;
}
.Lt_35years_img img {
    max-width: 80%;
}
.Lt_35years_section {
    padding: 55px 0px;
}

.bg_right img {
    max-width: 100%;
}
.bg_left img {
    max-width: 100%;
}

}

@media (min-width:768px) and (max-width:992px) {
.bg_left img {
    width: 100%;
}

.bg_left {
    position: absolute;
    left: 0;
    top: 10px;
    opacity: 0.1;
    width: 140px;
}

.bg_right img {
    width: 100%;
}

.bg_right {
    position: absolute;
    right: 0;
    top: inherit;
    bottom: 40px;
    opacity: 0.1;
    width: 140px;
}

}

@media (min-width:320px) and (max-width:767px) {
.bg_left {
    display: none;
}

.bg_right {
    display: none;
}
.Lt_35years_text {
    width: 100%;
    text-align: center;
    margin-left: 0px;
}
.Lt_35years_main {
    text-align: center;
    display: block;
}
.Lt_35years_text h2 {
    font-size: 30px;
    margin-top: 1px;
}

.Lt_35years_section {
    padding: 30px 0px;
}



}

/******************************** End Eng and wedding css **************************/


/* Best Seller */
.Lt_BestSeller {
    text-align: center;
}

.Lt_BestSeller a {
    color: #000;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
    font-family: 'Playfair Display', sans-serif;
}

.Lt_BestSeller img {
    max-width: 100%;
}

.Lt_title_text {
    text-align: center;
}

.Lt_bestSeller_section {
    padding: 90px 0px 20px 0px;
}

.Lt_title_text h2 {
    font-size: 40px;
    line-height: 55px;
    font-weight: 400;
    letter-spacing: 0;
}

.Lt_bestSeller_slider::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    height: 100%;
    background: rgb(255, 255, 255, 0.8);
    z-index: 9;
}

.Lt_bestSeller_slider::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    height: 100%;
    background: rgb(255, 255, 255, 0.8);
    z-index: 9;
}

.Lt_bestSeller_slider {
    position: relative;
}

/* .Lt_bestSeller_slider .slick-slider.slick-initialized button {
    left: 20px;
    z-index: 9;
    background: #a99575;
    width: 40px;
    height: 40px;
    border-radius: 50%;
} */

.Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-next {
    z-index: 9;
    right: 10%;
    background: #a99575;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 99;
}

.Lt_bestSeller_slider .slick-slider.slick-initialized .slick-prev:before,
.slick-next:before {
    color: transparent;
    background: url(../images/home/icons8-right-48.png);
    background-repeat: no-repeat !important;
    position: absolute;
    top: 0px;
    left: 5px;
    bottom: 0;
    right: 0;
    content: "";
    background-size: 75%;
    background-position: center;
}

.Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-prev {
    z-index: 9;
    left: 10%;
    background: #a99575;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-prev::before {
    color: transparent;
    background: url(../images/home/icons8-left-48.png);
    background-repeat: no-repeat !important;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    right: 0;
    content: "";
    background-size: 75%;
}

@media (max-width:1023px) {
    .Lt_bestSeller_slider::before {
        display: none;
    }

    .Lt_bestSeller_slider::after {
        display: none;
    }
}

@media (min-width:992px) and (max-width:1024px) {

    .Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-next {
        right: 17%;
    }

    .Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-prev {
        left: 17%;
    }

    .Lt_bestSeller_slider::before {
        width: 23%;
    }

    .Lt_bestSeller_slider::after {
        width: 23%;
    }


}

@media (min-width:768px) and (max-width:992px) {
    .Lt_bestSeller_section {
        padding: 60px 0px 20px 0px;
    }

    .Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-prev {
        left: -5px;
    }

    .Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-next {
        right: -5px;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-prev {
        z-index: 9;
        left: -5px;
    }

    .Lt_bestSeller_slider .slick-slider.slick-initialized button.slick-arrow.slick-next {
        right: -5px;
    }

    .Lt_bestSeller_section {
        padding: 40px 0px 20px 0px;
    }

    .Lt_title_text h2 {
        font-size: 30px;
        line-height: normal;
        font-weight: 400;
    }
}

/********************************End Best Seller Css **************************/

/* Why Buy Belgium Css */

.bri_service_box_mob .am_box img {
    margin: auto;
    padding-bottom: 10px;
}

.Lt_whyBuy_section {
    padding: 100px 0;
    background: #f6f6f6;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.am_box {
    text-align: center;
    padding: 0px 30px;
    margin-bottom: 40px;
}

.am_box img {
    max-width: 100%;
    margin-bottom: 10px;
}

.am_box h6 {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: .04em;
    margin-top: 10px;
    font-family: sans-serif !important;
}

.am_box p {
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    color: #7b7b7b;
    margin-top: 20px;
}

.abt-heading {
    color: #a99575;
    letter-spacing: 1px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    border-bottom: 1px solid #a99575;
    margin-top: 0px;
    margin-bottom: 40px;
}

@media (min-width:1200px) and (max-width:1440px) {
    .am_box p {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        margin-top: 5px;
    }

    .am_box h6 {
        font-size: 15px;
    }

    .Lt_title_text h2 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 400;
        letter-spacing: 0;
    }

    .abt-heading {
        letter-spacing: normal;
        font-weight: 500;
        margin-top: 10px;
        font-size: 12px;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .am_box p {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #7b7b7b;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .Lt_whyBuy_section {
        padding: 50px 0 20px 0;
    }
    .am_box {
        margin-bottom: 10px;
    }

}

/********************************End Why Buy Css **************************/

/* Video banner css */
.Lt_videoBanner_main {
    height: 720px;
    position: relative;
}

.Lt_videoBanner_main video {
    object-fit: cover;
}

.Lt_video_banner_content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    margin: auto;
    width: 40%;
    height: fit-content;
    z-index: 99;
    text-align: center;
}

.Lt_video_banner_content h2 {
    font-family: 'Playfair Display', sans-serif;
    color: #fff;
    font-size: 70px;
    line-height: 70px;
    font-weight: 400;
    -moz-transition: color .2s linear;
    -o-transition: color .2s linear;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
    margin-bottom: 20px;
}

.Lt_video_banner_content p {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
    width: 70%;
    margin: 20px auto;
}

.Lt_video_banner_content button {
    color: white;
    border-color: white !important;
}

.Lt_videoBanner_main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.60);
}


@media (min-width:1200px) and (max-width:1440px) {

    .Lt_video_banner_content {
        width: 55%;
    }

    .Lt_video_banner_content h2 {
        font-size: 46px;
        line-height: 42px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .Lt_video_banner_content p {
        color: #fff;
        font-size: 13px;
        line-height: 20px;
        width: 50%;
        margin: 20px auto;
    }

    .Lt_videoBanner_main {
        height: 540px;
    }

}

@media (min-width:992px) and (max-width:1024px) {
    .Lt_videoBanner_main {
        height: 550px;
    }

    .Lt_video_banner_content h2 {
        font-size: 60px;
        line-height: 60px;
    }

    .Lt_video_banner_content p {
        font-size: 18px;
        line-height: 26px;
        width: 70%;
    }

    .Lt_video_banner_content {
        width: 70%;
    }

}

@media (min-width:768px) and (max-width:992px) {
    .Lt_video_banner_content {
        width: 80%;
    }

    .Lt_video_banner_content p {
        font-size: 16px;
        line-height: 26px;
    }

    .Lt_videoBanner_main {
        height: 460px;
    }

    .Lt_video_banner_content h2 {
        font-size: 60px;
        line-height: 60px;
    }

}


@media (min-width:320px) and (max-width:767px) {
    .Lt_video_banner_content {
        width: 100%;
    }

    .Lt_video_banner_content h2 {
        font-size: 35px;
        line-height: 32px;
    }

    .Lt_video_banner_content p {
        font-size: 14px;
        line-height: 22px;
        width: 90%;
    }

    .Lt_videoBanner_main {
        height: 350px;
    }

}

/********************************End video banner Css **************************/

/*Learn Diamond */
.am1_box_mobile .zoom-image img {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    -moz-transition: all .5s ease-out 0s;
    -o-transition: all .5s ease-out 0s;
    max-width: 100%;
    min-height: 750px;

}

.am1_box_mobile .zoom-image:hover .position-btn a {
    display: block !important;
}

.position-btn a:hover {
    background: white;
    color: black;
}

.am1_box_mobile .zoom-image:hover img {
    transform: scale(1.1);
    overflow: hidden;
}

.Lt_LearnDiamond_section {
    padding: 100px 0;
}

.zoom-image {
    overflow: hidden;
    position: relative;
}

.position-btn {
    content: '';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.position-btn.btn-d1 a:hover {
    background: black;
    color: white;
}

.position-btn.btn-d1 a {
    border-color: black;
    color: black;
}
.position-btn a {
    padding: 10px 42px;
    min-width: 170px;
    max-width: 200px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border: 2px solid #fff;
    display: none;
    font-weight: 800;
    margin: 0 auto;
}

.contentsec {
    padding: 25px 0;
    text-align: center;
}

.contentsec h2 {
    font-family: 'Playfair Display', sans-serif;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 40px;
    line-height: 55px;
}

.contentsec p {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 27px;
}

@media (min-width:1200px) and (max-width:1440px) {
.Lt_LearnDiamond_section .container {
    max-width: 1250px;
}
}

@media (min-width:992px) and (max-width:1024px) {

    .am1_box_mobile .zoom-image img {
        min-height: 560px;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .am1_box_mobile .zoom-image img {
        min-height: 420px;
    }

    .contentsec h2 {
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;
    }

    .contentsec p {
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        line-height: 22px;
    }

    .Lt_LearnDiamond_section {
        padding: 70px 0 40px 0;
    }

    .zoom-image {
        text-align: center;
    }

}


@media (min-width:320px) and (max-width:767px) {
    .Lt_LearnDiamond_section {
        padding: 60px 0 30px 0;
    }

    .am1_box_mobile .zoom-image img {
        min-height: 400px;
    }

    .contentsec h2 {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin-top: 5px;
    }

    .contentsec p {
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        line-height: 22px;
    }
}

/********************************End Learn Diamond Css **************************/

/* what People say css start */
.slider-wrapper .nav-tabs .nav-item:first-child button:before {
    background: url('../images/home/google_review_img.jpg') no-repeat 50%/cover;
}

.slider-wrapper .nav-tabs .nav-item:nth-child(2) button:before {
    background: url('../images/home/yelp-reviews.jpg') no-repeat 50%/cover;
}

.slider-wrapper .nav-tabs .nav-item:nth-child(3) button:before {
    background: url('../images/home/ivouch-reviews.jpg') no-repeat 50%/cover;
}

.slider-wrapper .nav-tabs .nav-link.active {
    background-color: #d2ae76;
}

.slider-wrapper .nav-tabs .nav-link {
    width: 100px;
    height: 60px;
    background: #aaa;
    border-radius: 5px;
    border: none;
    position: relative;
}

.slider-wrapper .nav-tabs .nav-link {
    width: 130px;
    height: 80px;
    background: transparent;
    border-radius: 5px;
    border: none;
    position: relative;
    overflow: hidden;
}

.slider-wrapper .nav-tabs .nav-item button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 92%;
    height: 90%;
    border-radius: 5px;
}

.slider-wrapper .nav-tabs {
    border: none;
    justify-content: center;
    grid-gap: 15px;
}

.Lt_testimonials_section {
    background-color: #7f6750;
    padding: 60px 0 60px;
}

.Lt_testimonials_section .Lt_title_text h2 {
    color: white;
    text-transform: capitalize;
}

.slider-wrapper .nav-tabs {
    border: none;
    justify-content: center;
    grid-gap: 40px;
    margin: 35px 0px 45px 0px !important;
}


.Lt_people_item {
    text-align: center;
    padding: 0px 30px;
}

.Lt_people_item h3 {
    font-size: 18px;
}

.Lt_people_item p {
    margin-bottom: 15px;
    color: #fff;
    font-family: 'Playfair Display', sans-serif;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 400;
}

.Lt_people_item a {
    color: white;
}

.Lt_people_content_main svg.slick-arrow.slick-prev {
    color: white;
    width: 30px;
    height: 30px;
    left: -20px;
    top: 40px;
}

.Lt_people_content_main svg.slick-arrow.slick-next {
    color: white;
    width: 30px;
    height: 30px;
    right: -20px;
    top: 40px;
}

a.review_read_more {
    display: block;
}


@media (min-width:320px) and (max-width:767px) {
    .Lt_testimonials_section {
        background-color: #7f6750;
        padding: 40px 0 40px;
    }
    .london_banner_btn_outline.btn.btn-outline-dark {
        padding: 8px 27px;
        font-size: 12px;
        font-weight: 500;
    }

    .Lt_people_item {
        padding: 0px 20px;
    }

    .Lt_people_item p {
        font-size: 14px;
        letter-spacing: normal;
        font-weight: 300;
        margin-bottom: 5px;
        line-height: normal !important;
    }

    .slider-wrapper .nav-tabs {
        justify-content: center;
        grid-gap: 10px;
        margin: 15px 0px 25px 0px !important;
    }

    .slider-wrapper .nav-tabs .nav-link {
        width: 80px;
        height: 50px;

    }

    .Lt_people_content_main svg.slick-arrow.slick-prev {
        left: -5px;
    }

    .Lt_people_content_main svg.slick-arrow.slick-next {
        right: -5px;
    }


}

/********************************what people say Css End**************************/

.Lt_NewsLetter_section {
    padding: 80px 0;
    background-color: #f6f6f6;
}

.Lt_NewsLetter_content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 30px;
    color: #7b7b7b;
}

.Lt_NewsLetter_content h2 {
    margin-bottom: 25px;
    font-size: 34px;
    line-height: 44px;
    font-weight: 400;
    letter-spacing: .03em;
}

.Lt_NewsLetter_content {
    text-align: center;
    padding: 0px 30px;
}

.Lt_NewsLetter_section .rcs_signup_to_input_field .input-group button {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 20px;

}

.Lt_NewsLetter_content button.london_banner_btn_fill.btn.btn-primary {
    background: black;
    border-color: black;
    letter-spacing: .12em;
}

.Lt_NewsLetter_content p a {
    color: #a99575;
}

.Lt_NewsLetter_section .rcs_signup_to_input_field .input-group input {
    height: 50px;
}

.Lt_NewsLetter_content button.london_banner_btn_fill.btn.btn-primary:hover {
    background: #a99575;
    border-color: #a99575;
    color: white;
}

@media (min-width: 1024px) {
    .Lt_NewsLetter_border_left {
        border-left: 1px solid #d9d9d9;
        padding-left: 0;
    }
}


@media (min-width:320px) and (max-width:767px) {

    .Lt_NewsLetter_section {
        padding: 45px 0;
    }
    .Lt_NewsLetter_content p {
        font-size: 15px;
        line-height: 22px;
    }
    .Lt_NewsLetter_content h2 {
        font-size: 30px;
        line-height: 32px;
    }
    .Lt_NewsLetter_content .gs_news_form {
        margin-bottom: 40px;
    }
    .Lt_NewsLetter_content {
        padding: 0px 0px;
    }
}
/*******************************************************************/

.abt-breadcrumb h1,
.abt-heading-new {
    font-family: 'EB Garamond' !important;
}